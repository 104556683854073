import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <p>{`I grew up near Boston, Massachusetts before moving to Illinois, where I received a BS in Biomedical Engineering and an MS in Computer Science from Northwestern University in Evanston.`}</p>
    <h3>{`Recent Activity`}</h3>
    <h4>{`Conferences:`}</h4>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Github Satellite`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`React Summit`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Next.js Conf`}</li>

    </ul>
    <h4>{`Workshops`}</h4>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Javascript State Machines and XState`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Introduction to d3.js`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Automated Tagging System with TensorflowJS`}</li>

    </ul>
    <h4>{`Courses:`}</h4>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Introduction to Gatsby`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Design Systems with React and Storybook`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Full Stack for Front-End Engineering`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Functional-Light Javascript`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`REST & GraphQL API Design in Node.js (using Express & MongoDB)`}</li>

    </ul>
    <h4>{`Books:`}</h4>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Data Structures and Algorithms`}</li>

    </ul>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      