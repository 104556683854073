import React from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Technologies from "../components/technologies";
import Projects from "../components/projects"
import Companies from "../components/companies"
import About from "../components/about"
import Contact from "../components/contact"

const Template = () => (
  <Layout>
    <Parallax pages={9.5}>
      <Hero offset={0} factor={1} />
      <Technologies offset={1} factor={2} />
      <Projects offset={3} factor={2} />
      <Companies offset={5} factor={3} />
      <About offset={7.5} factor={1} />
      <Contact offset={8.5} factor={1} />
    </Parallax>
  </Layout>
)

export default Template
