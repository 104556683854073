/** @jsx jsx */
import { useState } from "react"
import { Link } from "gatsby"
import { Box, Flex, jsx, useThemeUI } from "theme-ui"
import styled from "styled-components"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import { UpDown, UpDownWide, waveAnimation } from "../styles/animations"
import Footer from "./footer"
// @ts-ignore
import ContactMDX from "../sections/contact"

const InnerWave = styled.div`
  path {
    ${waveAnimation(`20s`)};
  }
`

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  width: 200px;
  height: 40px;
  background: dodgerblue;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.67, 0.17, 0.4, 0.83);
  font-size: 18px;
  align-self: flex-end;
  margin-top: 16px;
  color: white;

  svg {
    transform: rotate(180deg);
    transition: all 0.5s;
  }

  ${({ success }) =>
    success &&
    `
    width: 120px;
    height: 120px;
    background: mediumseagreen;
    border-radius: 50%;
    transform: rotate(-180deg);
  `}

  &:hover {
    cursor: pointer;
  }
`

const Contact = ({ offset, factor = 1 }) => {
  const [status, setStatus] = useState("")
  const { colorMode } = useThemeUI()
  const bg = colorMode === "light" ? "white" : "black"
  const text = colorMode === "light" ? "black" : "white"
  return (
    <div>
      <Divider fill="divider" speed={0.2} offset={offset} factor={factor}>
        <div
          sx={{
            position: `absolute`,
            bottom: 0,
            width: `full`,
            transform: `matrix(1, 0, 0, -1, 0, 0)`,
          }}
        >
          <InnerWave
            sx={{
              position: `relative`,
              height: `full`,
              svg: { width: `100%`, height: `40vh` },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="contact-wave"
              viewBox="0 0 800 338.05"
              preserveAspectRatio="none"
            >
              <path>
                <animate
                  attributeName="d"
                  values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                  repeatCount="indefinite"
                  dur="30s"
                />
              </path>
            </svg>
          </InnerWave>
        </div>
      </Divider>
      <Content speed={0.4} offset={offset} factor={factor}>
        <Inner>
          <ContactMDX />
          <form
            onSubmit={ev => {
              ev.preventDefault()
              setStatus("SUBMITTING")
              const form = ev.target
              const data = new FormData(form)
              const xhr = new XMLHttpRequest()
              xhr.open(form.method, form.action)
              xhr.setRequestHeader("Accept", "application/json")
              xhr.onreadystatechange = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) return
                if (xhr.status === 200) {
                  form.reset()
                  setStatus("SUCCESS")
                } else {
                  setStatus("ERROR")
                }
              }
              xhr.send(data)
            }}
            action="https://formspree.io/f/mknpojwr"
            method="POST"
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "50vw",
              backgroundColor: bg,
              padding: "16px",
              color: text,
              boxShadow:
                "15px 15px 1px #ffa580, 15px 15px 1px 2px rgba(0,0,0,1)",
            }}
          >
            <label>Name:</label>
            <input
              type="text"
              name="name"
              aria-label="name"
              sx={{
                backgroundColor: bg,
                color: text,
                border: `1px solid ${text}`,
                borderRadius: "4px",
                height: "30px",
              }}
            />
            <label>Email:</label>
            <input
              type="email"
              name="email"
              aria-label="email"
              sx={{
                backgroundColor: bg,
                color: text,
                border: `1px solid ${text}`,
                borderRadius: "4px",
                height: "30px",
              }}
            />
            <label>Message:</label>
            <textarea
              rows="5"
              name="message"
              id="message"
              aria-label="message"
              placeholder=""
              required=""
              sx={{
                backgroundColor: bg,
                color: text,
                border: `1px solid ${text}`,
                borderRadius: "4px",
              }}
            />
            <input
              type="hidden"
              name="_subject"
              id="email-subject"
              value="Contact Form Submission"
            />
            <SubmitButton success={status === "SUCCESS"}>
              {status === "SUCCESS" ? (
                <svg
                  width="58"
                  height="45"
                  viewBox="0 0 58 45"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#fff"
                    fill-rule="nonzero"
                    d="M19.11 44.64L.27 25.81l5.66-5.66 13.18 13.18L52.07.38l5.65 5.65"
                  />
                </svg>
              ) : (
                "Submit"
              )}
            </SubmitButton>
            {status === "ERROR" && <p>Ooops! There was an error.</p>}
          </form>
        </Inner>
        <Box sx={{ marginTop: "2.5rem" }}>
          <Flex
            sx={{
              justifyContent: `center`,
              alignItems: `center`,
              mt: 3,
              color: `text`,
              fontWeight: `semibold`,
              a: { color: `text` },
            }}
          >
            <img
              width="30"
              height="30"
              src="https://img.lekoarts.de/gatsby/logo_w30.png"
              alt="LekoArts Logo"
            />
            {` `}
            <Link
              aria-label="Link to the theme's GitHub repository"
              sx={{ ml: 2, marginRight: "24px" }}
              href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-cara"
            >
              Theme Inspiration
            </Link>
            {` `}
            <Link
              aria-label="Link to the theme author's website"
              href="https://www.lekoarts.de/en"
              sx={{ textAlign: "right" }}
            >
              Based on the Gatsby Theme by LekoArts
            </Link>
            <Box as="footer" variant="footer">
              Copyright &copy; {new Date().getFullYear()}. All rights reserved.
            </Box>
          </Flex>
        </Box>
      </Content>
      <Divider speed={0.1} offset={offset} factor={factor}>
        <UpDown>
          <SVG
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="70%"
            top="20%"
          />
          <SVG
            icon="triangle"
            width={8}
            stroke
            color="icon_darkest"
            left="25%"
            top="5%"
          />
        </UpDown>
        <UpDownWide>
          <SVG
            icon="triangle"
            width={12}
            stroke
            color="icon_brightest"
            left="95%"
            top="50%"
          />
          <SVG
            icon="circle"
            width={6}
            color="icon_brightest"
            left="85%"
            top="15%"
          />
          <SVG
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="45%"
            top="10%"
          />
        </UpDownWide>
        <SVG
          icon="circle"
          width={6}
          color="icon_brightest"
          left="4%"
          top="20%"
        />
        <SVG
          icon="circle"
          width={12}
          color="icon_darkest"
          left="70%"
          top="60%"
        />
        <SVG icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
        <SVG
          icon="hexa"
          width={8}
          stroke
          color="icon_darkest"
          left="80%"
          top="70%"
        />
      </Divider>
    </div>
  )
}

export default Contact
