import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import TechnologyCard from "../components/technology-card";
import ReactImg from "../../static/react.svg";
import Redux from "../../static/redux.svg";
import Node from "../../static/node.svg";
import Python from "../../static/python.svg";
import Swift from "../../static/swift.svg";
import NGINX from "../../static/nginx.svg";
import Git from "../../static/git.svg";
import Github from "../../static/github.svg";
import Jenkins from "../../static/jenkins.svg";
import CircleCi from "../../static/circleci.svg";
import Kubernetes from "../../static/kubernetes.svg";
import EC2 from "../../static/ec2.svg";
import Ember from "../../static/ember.svg";
import Java from "../../static/java.svg";
import Go from "../../static/golang.svg";
import { Grid, jsx } from "theme-ui";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h2>{`Experience`}</h2>
    <Grid width={325} gap={11} columns={[1, 2, 3]} mdxType="Grid">
      <TechnologyCard img={ReactImg} mdxType="TechnologyCard">
        <p>{`React / React Native`}</p>
      </TechnologyCard>
      <TechnologyCard img={Redux} mdxType="TechnologyCard">
        <p>{`Redux`}</p>
      </TechnologyCard>
      <TechnologyCard img={Ember} mdxType="TechnologyCard">
        <p>{`Ember`}</p>
      </TechnologyCard>
      <TechnologyCard img={Node} mdxType="TechnologyCard">
        <p>{`Node`}</p>
      </TechnologyCard>
      <TechnologyCard img={Python} mdxType="TechnologyCard">
        <p>{`Python`}</p>
      </TechnologyCard>
      <TechnologyCard img={Java} mdxType="TechnologyCard">
        <p>{`Java`}</p>
      </TechnologyCard>
      <TechnologyCard img={Go} mdxType="TechnologyCard">
        <p>{`Go`}</p>
      </TechnologyCard>
      <TechnologyCard img={Swift} mdxType="TechnologyCard">
        <p>{`Swift`}</p>
      </TechnologyCard>
      <TechnologyCard img={NGINX} mdxType="TechnologyCard">
        <p>{`NGINX`}</p>
      </TechnologyCard>
      <TechnologyCard img={Git} mdxType="TechnologyCard">
        <p>{`Git`}</p>
      </TechnologyCard>
      <TechnologyCard img={Github} mdxType="TechnologyCard">
        <p>{`Github`}</p>
      </TechnologyCard>
      <TechnologyCard img={Jenkins} mdxType="TechnologyCard">
        <p>{`Jenkins`}</p>
      </TechnologyCard>
      <TechnologyCard img={CircleCi} mdxType="TechnologyCard">
        <p>{`CircleCI`}</p>
      </TechnologyCard>
      <TechnologyCard img={Kubernetes} mdxType="TechnologyCard">
        <p>{`Kubernetes`}</p>
      </TechnologyCard>
      <TechnologyCard img={EC2} mdxType="TechnologyCard">
        <p>{`AWS EC2`}</p>
      </TechnologyCard>
    </Grid>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      