import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import ProjectCard from "../components/project-card";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Formatted-Input" link="https://www.npmjs.com/package/formatted-input" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
      <p>{`A small, controlled React component allowing customized display formatting
while preserving raw user input.`}</p>
    </ProjectCard>
    <ProjectCard title="Rulp" link="https://crates.io/crates/rulp" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
      <p>{`A linear programming library written in Rust that uses the Simplex method to
solve user problems.`}</p>
    </ProjectCard>
    <ProjectCard title="Beer Graphics" link="https://beer-graphics.vercel.app/" bg="linear-gradient(to right, #02aab0 0%, #00cdac 100%)" mdxType="ProjectCard">
      <p>{`A d3 based project that sources data from BreweryDB through a Next.js
fullstack app to render images representing each beer, hosted by Vercel.`}</p>
    </ProjectCard>
    <ProjectCard title="Rudd or Wrong" link="https://expo.io/@andrewjmcconn/projects/rudd-or-wrong" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
      <p>{`An image recognition mobile app that scans for Paul Rudd among faces detected
on the camera stream.`}</p>
    </ProjectCard>
    <ProjectCard title="Portfolio" link="https://andrewjmcconnell.com" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
      <p>{`This site was built using Gatsby.js and deployed by Netlify.`}</p>
    </ProjectCard>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      