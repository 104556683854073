/** @jsx jsx */
import { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"
import { jsx, useThemeUI } from "theme-ui"
import {
  styled as styledTabTab,
  PanelList,
  Tabs,
  TabList,
  Tab,
  Panel,
} from "react-tabtab"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import { UpDown, UpDownWide } from "../styles/animations"
import CompaniesMDX from "../sections/companies"

let { TabListStyle, TabStyle, PanelStyle, ActionButtonStyle } = styledTabTab

const Companies = ({ offset, factor = 1 }) => {
  const [activeIndex, setActiveIndex] = useState(3)
  const { colorMode } = useThemeUI()
  const bg = colorMode === "light" ? "white" : "black"
  const tabListBg = colorMode === "light" ? "lightGrey" : "black"
  const tabBg = colorMode === "light" ? "white" : "darkGrey"
  const text = colorMode === "light" ? "black" : "white"
  const style = { bg, tabBg, text }
  const { nielsen, optum, citybase, square } = useStaticQuery(graphql`
    query {
      nielsen: file(relativePath: { eq: "nielsen.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      optum: file(relativePath: { eq: "optum.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      citybase: file(relativePath: { eq: "citybase.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      square: file(relativePath: { eq: "square.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const TabListStyled = styled(TabListStyle)`
    background-color: ${tabListBg};
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: visible;
    white-space: normal;

    > div {
      overflow: visible;
    }
  `
  const TabStyled = styled(TabStyle)`
    color: rgba(238, 110, 115, 0.7);
    transition: color 0.28s ease;
    background-color: ${tabBg};
    height: 100px;
    vertical-align: top;
    border-top: 1px solid ${tabBg};
    margin-left: 4px;
    margin-top: -16px;
    margin-bottom: 24px;
    z-index: 10;
    border-radius: 4px;
    ${props =>
      props.active && !props.vertical
        ? `
  border-bottom: 2px solid #f6b2b5;
`
        : null}
    &:hover {
      border-bottom: 2px solid #f6b2b5;
    }
  `
  const PanelStyled = styled(PanelStyle)`
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 30px 30px;
    transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
    border-radius: 2px;
    background-color: ${bg};
    color: ${text};
  `
  const ActionButtonStyled = styled(ActionButtonStyle)`
    display: none !important;
  `
  return (
    <div>
      <Divider
        bg="linear-gradient(to right, red 0%, orange 100%)"
        sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
        speed={-0.2}
        offset={offset + 0.1}
        factor={1.5}
      />
      <Content speed={0.4} offset={offset + 0.2} factor={factor}>
        <Inner>
          <CompaniesMDX />
          <Tabs
            defaultIndex={2}
            activeIndex={activeIndex}
            onTabChange={setActiveIndex}
            customStyle={{
              TabList: TabListStyled,
              Tab: TabStyled,
              Panel: PanelStyled,
              ActionButton: ActionButtonStyled,
            }}
          >
            <TabList>
              <Tab>
                <Image
                  fluid={nielsen.sharp.fluid}
                  alt="Nielsen"
                  sx={{
                    width: 200,
                  }}
                />
              </Tab>
              <Tab>
                <Image
                  fluid={optum.sharp.fluid}
                  alt="OptumRx"
                  sx={{
                    width: 200,
                  }}
                />
              </Tab>
              <Tab {...style}>
                <Image
                  fluid={citybase.sharp.fluid}
                  alt="CityBase"
                  sx={{
                    width: 200,
                  }}
                />
              </Tab>
              <Tab>
                <Image
                  fluid={square.sharp.fluid}
                  alt="Square"
                  sx={{
                    width: 200,
                  }}
                />
              </Tab>
            </TabList>
            <PanelList>
              <Panel>
                <h3 sx={{ fontStyle: "italic" }}>Technical Intern</h3>
                <ul>
                  <li>
                    Built a framework to migrate ODS (operational data store)
                    DataStage code to Python script. The current platform
                    provides an interface allowing Nielsen customers access to
                    large stores of consumer data.
                  </li>
                  <li>
                    New code targeted two key ETL data requests associated with
                    the Answers On Demand service, providing a cheaper, faster
                    and easier-to-use customer interface intended for scale
                    across all ETL use cases.
                  </li>
                </ul>
              </Panel>
              <Panel>
                <h3 sx={{ fontStyle: "italic" }}>
                  Technology Development Program Senior Associate, Mobile
                  Application Developer
                </h3>
                <ul>
                  <li>
                    Developer a Kibana application plugin in conjunction with
                    setting up an Elasticsearch cluster for internal application
                    monitoring. Using Cytoscape.js and React to create a
                    real-time visualization suite to monitor business flows and
                    search for and flag anomalies/system failures.
                  </li>
                  <li>
                    Spun up rapid prototype cross-platform diabetes management
                    app. Used Cordova, Framework7 and Vue to create a working
                    model app to be handed off for full production.
                  </li>
                  <li>
                    Created a native iOS mobile app from scratch in Swift to
                    support specialty pharmacy business, to replace jQuery
                    mobile app.
                  </li>
                </ul>
              </Panel>
              <Panel>
                <h3 sx={{ fontStyle: "italic" }}>Software Engineer</h3>
                <ul>
                  <li>
                    Developing highly customizable Payment and Profile
                    software-as-a-service applications that can be configured to
                    any client or user.
                  </li>
                  <li>
                    Helped in the full stack development of an Invoice payment
                    system, allowing client websites to redirect from their own
                    website to Citybase's Checkout system while maintaining user
                    and payment information.
                  </li>
                  <li>
                    Spent time in product support and developer-on-call
                    rotations to assist clients' P0 issues, solving problems in
                    production environments.
                  </li>
                  <li>
                    Development responsibilities include feature work,
                    end-to-end testing, accessibility improvements, continuous
                    integration and deployment, and more.
                  </li>
                </ul>
              </Panel>
              <Panel>
                <h3 sx={{ fontStyle: "italic" }}>Senior Software Engineer</h3>
                <p>As a fullstack developer, helped develop and launch Square's Subscriptions from beta to a publicly available API, integrated across Square's first party platforms. In 2023, the Square Subscriptions platform had 22k active sellers, 388k active subscriptions, and processed $178M in yearly recurring revenue.</p>
                <ul>
                  <li>Designed, built and maintained the first-party Subscriptions offerings on the Seller Dashboard and Buyer Management portal, where the buyers and sellers of Square Subscriptions could create new or manage existing subscriptions, with extensive configurability.</li>
                  <li>Collaborated extensively with other teams to ensure cohesive integration of the Subscriptions API across other Square offerings, such as Subscribe & Save functionality on seller websites.</li>
                  <li>Built out substantial internal tooling to improve or eliminate common problems, such as
                  recurring oncall issues that wasted developers' time, or admin dashboards to give
                  stakeholders insight into project progress.</li>
                  <li>Ran a number of learning and development opportunities, such as new employee
                  orientation, team-scoped knowledge transfers, conducting technical interviews, and intern
                  mentoring.</li>
                </ul>
              </Panel>
            </PanelList>
          </Tabs>
        </Inner>
      </Content>
      <Divider speed={0.1} offset={offset} factor={factor}>
        <UpDown>
          <SVG
            icon="box"
            width={6}
            color="icon_brightest"
            left="85%"
            top="75%"
          />
          <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
          <SVG
            icon="triangle"
            width={8}
            stroke
            color="icon_orange"
            left="25%"
            top="5%"
          />
          <SVG
            icon="circle"
            hiddenMobile
            width={24}
            color="icon_brightest"
            left="17%"
            top="60%"
          />
        </UpDown>
        <UpDownWide>
          <SVG
            icon="arrowUp"
            hiddenMobile
            width={16}
            color="icon_green"
            left="20%"
            top="90%"
          />
          <SVG
            icon="triangle"
            width={12}
            stroke
            color="icon_brightest"
            left="90%"
            top="30%"
          />
          <SVG
            icon="circle"
            width={16}
            color="icon_yellow"
            left="70%"
            top="90%"
          />
          <SVG
            icon="triangle"
            hiddenMobile
            width={16}
            stroke
            color="icon_teal"
            left="18%"
            top="75%"
          />
          <SVG
            icon="circle"
            width={6}
            color="icon_brightest"
            left="75%"
            top="10%"
          />
          <SVG
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_green"
            left="45%"
            top="10%"
          />
        </UpDownWide>
        <SVG
          icon="circle"
          hiddenMobile
          width={6}
          color="icon_brightest"
          left="4%"
          top="20%"
        />
        <SVG icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
        <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <SVG icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
        <SVG
          icon="hexa"
          width={16}
          stroke
          color="icon_red"
          left="75%"
          top="30%"
        />
        <SVG
          icon="hexa"
          width={8}
          stroke
          color="icon_yellow"
          left="80%"
          top="70%"
        />
      </Divider>
    </div>
  )
}

export default Companies
