/** @jsx jsx */
import React from "react"
import { createGlobalStyle } from "styled-components"
import { jsx } from "theme-ui"
import SEO from "./seo"

const Global = createGlobalStyle`
  * { 
    box-sizing: inherit;
    &:before {
      box-sizing: inherit;
    }
    &:after {
      box-sizing: inherit;
    }
  }

  html {
    font-size: 18px;
  }

  img {
    border-style: none;
  }

  pre {
    font-family: monospace;
    font-size: 1rem;
  }

  [hidden] {
    display: none;
  }
`

const Layout = ({ children, className = `` }) => (
  <React.Fragment>
    <Global
    // styles={theme => ({
    //   "::selection": {
    //     backgroundColor: theme.colors.primary,
    //     color: theme.colors.background,
    //   },
    // })}
    />
    <SEO />
    <main className={className}>{children}</main>
  </React.Fragment>
)

export default Layout
